import { createStore } from 'vuex'

export default createStore({
  state: {
    accessToken : localStorage.accessToken,
    ndqdjkzt:localStorage.ndqdjkzt,//您当前的健康状态
    sfdgzgfxq:localStorage.sfdgzgfxq,//是否到过中高风险区
    sfjcgxgbr:localStorage.sfjcgxgbr,//是否接触过新冠病人
    jzlxdh:localStorage.jzlxdh//家长联系电话
  },
  getters: {
  },
  mutations: {
    setToken(state,value){
      state.accessToken = value;
      localStorage.accessToken = value;
    },
    setNdqdjkzt(state,value){
    	state.ndqdjkzt=value;
    	localStorage.ndqdjkzt=value;    	
    },
    setSfdgzgfxq(state,value){
    	state.sfdgzgfxq = value;
      localStorage.sfdgzgfxq = value;
    },
    setSfjcgxgbr(state,value){
    	state.sfjcgxgbr = value;
      localStorage.sfjcgxgbr = value;
    },
    setJzlxdh(state,value){
    	state.jzlxdh = value;
      localStorage.jzlxdh = value;
    }
  },
  actions: {
  },
  modules: {
  }
})
