import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Dxsqsz from '../views/dxsqsh/Dxsqsz.vue'
import Dxsqsh from '../views/dxsqsh/Dxsqsh.vue'
import DxsqDetail from '../views/dxsqsh/DxsqDetail.vue'
import Dxsq from '../views/dxsq/Dxsq.vue'
import Dxsqbd from '../components/dxsq/DxsqBd.vue'
import Dxsqjg from '../components/dxsq/Dxsqjg.vue'
import { h } from 'vue';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
  	path: '/xsyqfk/xstb',
	 	name: 'xstb',
		component:()=>import('@/App.vue'),
	  	children: [
	  		{
          		path: 'wdtbjl',
          		name: 'wdtbjl',
          		meta: {
		        	title: '我的填报记录'
		      	},
						component:()=> import('@/views/xstb/wdtbjl.vue'),
        },
	  		{
          		path: 'jktb',
          		name: 'jktb',
          		meta: {
		        	title: '健康填报'
		      	},
				component:()=> import('@/views/xstb/jktb.vue'),
        	},
        	{
          		path: 'rlhy',
          		name: 'rlhy',
          		meta: {
		        	title: '人脸核验'
		      	},
						component:()=> import('@/views/xstb/rlhy.vue'),
        	},
        	{
          		path: 'xstbwc',
          		name: 'xstbwc',
          		meta: {
		        	title: '填报完成'
		      	},
						component:()=> import('@/views/xstb/xstbwc.vue'),
        	},
	  ]
	},
	{
    path: '/jsyqfk',
    name: 'jssy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/sy/jssy.vue')
  },
  {
    path: '/xsyqfk',
    name: 'xssy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/sy/xssy.vue')
  },
  {
    path: '/jsyqfk/dxsq',
	  name: 'jsdxsq',
		component:()=>import('@/App.vue'),
	  children: [
        {
          path: 'dxsqsz',
          name: 'dxsqsz',
          meta: {
		        title: '到校申请设置'
		      },
					component:Dxsqsz,
        },
        {
          path: 'dxsqsh',
          name: 'dxsqsh',
          meta: {
		        title: '到校申请审核',
            keepAlive: true
		      },
					component:Dxsqsh,
        },
        {
          path: 'dxsqdetail',
          name: 'dxsqdetail',
          meta: {
		        title: '到校申请审核详情',
            keepAlive: true
		      },
					component:DxsqDetail,
        }
    ]
	},
  {
    path: '/xsyqfk/dxsq',
	  name: 'xsdxsq',
		component:()=>import('@/App.vue'),
	  children: [
        {
          path: 'dxsq',
          name: 'dxsq',
          meta: {
		        title: '到校申请'
		      },
					component:Dxsq,
        },
    ]
	},
	{
    path: '/jsyqfk/yqfk',
	  name: 'jsyqfk',
		component:()=>import('@/App.vue'),
	  children: [
        {
          path: 'yqfksz',
          name: 'yqfksz',
          meta: {
		        title: '疫情防控设置'
		      },
					component:()=> import('@/views/jsyqfk/yqfk/yqfksz.vue'),
        },
        {
          path: 'yqtbtj',
          name: 'yqtbtj',
          meta: {
		        title: '疫情填报统计'
		      },
					component:()=> import('@/views/jsyqfk/yqfk/yqtbtj.vue'),
        },
        {
          path: 'bbdc',
          name: 'bbdc',
          meta: {
		        title: '报表导出'
		      },
					component:()=> import('@/views/jsyqfk/yqfk/bbdc.vue'),
        },
         {
          path: 'yqtbxq',
          name: 'yqtbxq',
          meta: {
		        title: '疫情填报详情'
		      },
					component:()=> import('@/views/jsyqfk/yqfk/yqtbxq.vue'),
        },
		  //到校管理
		  {
			  path: 'dxgl',
			  name: 'DxMange',
			  component:()=>import('@/views/jsyqfk/dxgl/DxMange.vue'),
		  },
		  {
			  path: 'dxgl/:yhid/:dxpc',
			  name: 'DxMangeDetail',
			  component:()=>import('@/views/jsyqfk/dxgl/DxMangeDetail.vue'),
		  },
		  {
			  path: 'dxtj',
			  name: 'DxTj',
			  component:()=>import('@/views/jsyqfk/dxgl/DxTj.vue'),
		  },

    ]
	},
  {
  	path: '/uploadTest',
	  name: 'uploadTest',
		component:()=>import('@/views/UploadTest.vue'),
  }
]

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
