import { axPost, axGet } from '../utils/request.js';

// 教师端到校申请API
export const apiGetDxsqsh = p => axPost('/yqdk/dxsqls/cxDxsqsh', p)
export const apiGetDxsqszS = () => axPost('/yqdk/dxsqls/cxDxsqsz')
export const apiGetDxsqszC = p => axPost('/yqdk/dxsqls/cxJcsjlb', p)
export const apiSetDxsqsz = p => axPost('/yqdk/dxsqls/dxsqsz', p)
export const apiGetBjlb = p => axGet('/yqdk/dxsqls/getBjList', p)
export const apiGetXylb = () => axGet('/yqdk/dxsqls/getXyList')
export const apiGetZylb = p => axGet('/yqdk/dxsqls/getZyList', p)
export const apiSetTgsq = p => axPost('/yqdk/dxsqls/tgdxsq', p)
export const apiGetDxshxqwt = p => axPost('/yqdk/dxsqls/cxDxsqshxqWt', p)
export const apiGetDxshxq = p => axGet('/yqdk/dxsqls/cxDxsqshxq', p)
// 学生端到校申请API
export const apiGetDxsqWttm = () => axPost('/yqdk/dxsqxs/cxDxsqWttm')
export const apiGetDxsqshxx = p => axPost('/yqdk/dxsqxs/cxDxsqsh', p)
export const apiSetCxsq = p => axPost('/yqdk/dxsqxs/cxsq', p)
export const apiSetDxsqWttm = p => axPost('/yqdk/dxsqxs/tjDxsqWttm', p)
export const apiUploadImg = p => axPost('/yqdk/dxsqxs/sctp', p,'formData')
export const apiGetImg = p => axGet('/yqdk/dxsqxs/cktp',p)
export const apiGetFxdq = p => axGet('/yqdk/dxsqxs/cksfzgfx',p)
// 公共API
export const apiGetUser = () => axGet('/yqdk/yqdkUserInfo')
export const apiGetToken = p => axGet('/yqdk/getToken', p)
export const getIndexBackUrl = p => axGet('/yqdk/dxsqxs/getIndexBackUrl',p)
export const apiGetJsdm = () => axGet('/yqdk/getJsdm')

//到校管理
export const apiGetDxglList = p => axPost('/yqdk/dxsqls/dxglList', p)//到校管理列表
export const apiGetDxglXsxx = p => axGet('/yqdk/dxsqls/dxglXsxx', p)//到校管理详情
export const apiUpdateDxzt = p => axPost('/yqdk/dxsqls/updateDxzt', p,true)//更改到校状态
export const apiDxglTj = p => axPost('/yqdk/dxsqls/dxglTj', p)//到校统计
