export declare type ListType = "dsh | shtg | shwtg"

export declare type Data = {
    sqid: string,
    xm: string,
    yhid: string,
    zydm_id: string,
    zymc: string,
    bmdm_id: string,
    bmmc: string,
    bjdm_id: string,
    bjmc: string,
    dz: string,
    ycyyList:string[]
}

export interface Params {
    bjdm_id?: string,
    bmdm_id?: string,
    current?: string,
    kznr?: string,
    shzt?: string,
    size?: string,
    yhid?: string,
    zydm_id?: string
}

export const tagText = new Map([
    ["twycA", "体温正常"],
    ["twycB","体温异常"],
    ["jkztycA","健康"],
    ["jkztycB","健康异常"],
    ["zgfxdqA","无中高风险"],
    ["zgfxdqB","有中高风险"],
    ["jcqzrqA","无接触"],
    ["jcqzrqB","有接触"],
    ["swjzsA","无外省"],
    ["swjzsB","有外省"]]) 

export const mockdata = [{
    sqid: "1",
    xm: "ABC",
    yhid: "1-1",
    zydm_id: "ABBB专业",
    zymc: "ABBB专业",
    bmdm_id: "aaaa学院",
    bmmc: "aaaa学院",
    bjdm_id: "aaaaaa",
    bjmc: "a班",
    dz: "地址2333",
    ycyyList: ["twyc","jkyc"],
},{
    sqid: "4",
    xm: "ABC",
    yhid: "1-1",
    zydm_id: "ABBB专业",
    zymc: "ABBB专业",
    bmdm_id: "aaaa学院",
    bmmc: "aaaa学院",
    bjdm_id: "aaaaaa",
    bjmc: "a班",
    dz: "地址2333",
    ycyyList: ["zgfxdq","jkyc"],
},{
    sqid: "3",
    xm: "ABC",
    yhid: "1-1",
    zydm_id: "ABBB专业",
    zymc: "ABBB专业",
    bmdm_id: "aaaa学院",
    bmmc: "aaaa学院",
    bjdm_id: "aaaaaa",
    bjmc: "a班",
    dz: "地址2333",
    ycyyList: ["twyc"],
},{
    sqid: "2",
    xm: "ABC",
    yhid: "1-1",
    zydm_id: "ABBB专业",
    zymc: "ABBB专业",
    bmdm_id: "aaaa学院",
    bmmc: "aaaa学院",
    bjdm_id: "aaaaaa",
    bjmc: "a班",
    dz: "地址2333",
    ycyyList: ["twyc"],
},]