import axios from "axios";
import qs from "qs";
import { Toast } from 'vant';
import store from "../store"
import { router } from "@/router/index"
axios.interceptors.request.use(
  (config) => {
    // console.log(config)
    console.log(store.state.accessToken);
    config.headers["accessToken"] = store.state.accessToken;
  	return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    const res = response.data;
    if(res.code == 11){
      if(router.currentRoute.name != 'xssy' && router.currentRoute.name != 'jssy'){
        if(window.location.href.includes('xsyqfk')){
          router.push({path:'/xsyqfk'})
        } else {
          router.push({path:'/jsyqfk'})
        }
      }
    }
		return res
  },
  (error) => {
    return Promise.reject(error);
  }
);

function showMsg(res, showMsgFlag, msg) {
	let m = "";
  if (res.code === 0 && showMsgFlag != "showError") {
    m = msg.s ? msg.s : '操作成功';
		Toast(m);
  } else if (res.code != 0) {
    m = msg.w ? msg.w : res.message;
		Toast(m);
  }
}

//get
export const axGet = (
  url,
  params = {},
  type = "json",
  showMsgFlag = false,
  msg = {}
) => {
  if (typeof type === "boolean" || type === "showError") {
    showMsgFlag = type;
  } else if (type === "urlencoded") {
    url += "?" + qs.stringify(params);
    params = {};
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false });
        },
      })
      .then((res) => {
        resolve(res);
        if (showMsgFlag) {
          showMsg(res, showMsgFlag, msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//post
export const axPost = (
  url,
  params = {},
  type = "json",
  showMsgFlag = false,
  msg = {}
) => {
  if (typeof type === "boolean") {
    showMsgFlag = type;
  }
  let headers = {};
  let formData = new FormData();
  switch (type) {
    case "json":
      headers = {
        "Content-Type": "application/json;",
      };
      break;
    case "urlencoded":
      params = qs.stringify(params);
      headers = {
        "Content-Type": "application/x-www-form-urlencoded;",
      };
      break;
    case "formData":
      for (let key in params) {
        formData.append(key, params[key]);
      }
      params = formData;
      headers = {
        "Content-Type": "multipart/form-data;",
      };
      break;
    default:
      break;
  }

  return new Promise((resolve, reject) => {
    axios
      .post(url, params, { headers })
      .then((res) => {
        resolve(res);
        if (showMsgFlag) {
          showMsg(res, showMsgFlag, msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//delete
export const axDelete = (
  url,
  params = {},
  type = "urlencoded",
  showMsgFlag = false,
  msg = {}
) => {
  if (typeof type === "boolean") {
    showMsgFlag = type;
  }
  let headers = {};
  let formData = new FormData();
  switch (type) {
    case "json":
      headers = {
        "Content-Type": "application/json;",
      };
      break;
    case "urlencoded":
      params = qs.stringify(params);
      headers = {
        "Content-Type": "application/x-www-form-urlencoded;",
      };
      break;
    case "formData":
      for (let key in params) {
        formData.append(key, params[key]);
      }
      params = formData;
      headers = {
        "Content-Type": "multipart/form-data;",
      };
      break;
    default:
      break;
  }

  return new Promise((resolve, reject) => {
    axios
      .delete(url, params, { headers })
      .then((res) => {
        resolve(res);
        if (showMsgFlag) {
          showMsg(res, showMsgFlag, msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
