import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css'
import './assets/css/base.css'
import { ImagePreview, Cascader, Slider, RadioGroup, Radio, Collapse, CollapseItem, Step, Steps, PullRefresh, Cell, CellGroup, Overlay, Swipe, SwipeItem, Switch, Uploader, Search, Tag, Field, Popup, Picker, DatetimePicker, ActionSheet, List, Dialog, SwipeCell, Toast, Checkbox, CheckboxGroup, Tab, Tabs, IndexBar, IndexAnchor, Tabbar, TabbarItem, Icon, Progress, Loading, DropdownMenu, DropdownItem, Button, Sidebar, SidebarItem, TreeSelect, Row, Col, Card, Form, Pagination, NavBar, Calendar, Empty } from 'vant';
const app=createApp(App);

app.use(store);
app.use(router);
app.mount('#app');
app.use(RadioGroup);
app.use(Radio);
app.use(ImagePreview);
app.use(Cascader);
app.use(Collapse).use(CollapseItem);
app.use(Step).use(Steps);
app.use(PullRefresh);
app.use(Cell).use(CellGroup);
app.use(Overlay);
app.use(Swipe).use(SwipeItem);
app.use(Switch);
app.use(Slider);
app.use(Uploader);
app.use(Search);
app.use(Tag);
app.use(Field);
app.use(Popup);
app.use(Picker);
app.use(DatetimePicker);
app.use(ActionSheet);
app.use(List);
app.use(Dialog);
app.use(SwipeCell);
app.use(Toast);
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(Tab);
app.use(Tabs);
app.use(IndexBar);
app.use(IndexAnchor);
app.use(Tabbar).use(TabbarItem);
app.use(Icon);
app.use(Progress);
app.use(Loading);
app.use(DropdownMenu).use(DropdownItem);
app.use(Button);
app.use(Sidebar);
app.use(SidebarItem);
app.use(TreeSelect);
app.use(Row);
app.use(Col);
app.use(Card);
app.use(Form);
app.use(Pagination);
app.use(NavBar);
app.use(Calendar);
app.use(Empty);
